import styled from "styled-components";
import { BaseGraphProps } from "../containers/MonthlyReportContainer";
import { useCaregiverManual } from "./CaregiverManual.hook";
import Accordion from "../../common/components/Accordion";

export default function CaregiverManual(props: BaseGraphProps) {
  const {
    models: { data, loading },
  } = useCaregiverManual(props);

  return (
    <Accordion title="요양보호사 매뉴얼 안내" isLoading={loading}>
      <div>
        {data?.map((manual, index) => (
          <ManualSegment key={manual?.actNm || "manual" + index}>
            <p>
              {manual?.catNm}: {manual?.actNm}
            </p>
            <p>{manual?.actDetail}</p>
          </ManualSegment>
        ))}
      </div>
    </Accordion>
  );
}

const ManualSegment = styled.div(() => ({
  "&>p:first-of-type": {
    fontWeight: "bold",
    textDecoration: "underline",
  },
}));
