import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { useGetCaregiverInfosQuery } from "../../../lib/apollo/graphql/generated";
import { FETCH_SIZE } from "../../../utilities/constants";
import { useNavigate } from "react-router-dom";

export const useInfoContainer = () => {
  const navigate = useNavigate();

  const [fetchingMore, setFetchingMore] = useState(false);

  const onViewChange = (inView: boolean) => {
    const pageInfo = data?.getCaregiverInfos.pageInfo;

    if (inView && !fetchingMore && pageInfo?.hasNextPage) {
      setFetchingMore(true);

      fetchMore({
        variables: { first: FETCH_SIZE, after: pageInfo?.endCursor },
      }).finally(() => {
        setFetchingMore(false);
      });
    }
  };

  const [fetchMoreRef] = useInView({
    threshold: 0.3,
    onChange: onViewChange,
  });

  const { data, loading, fetchMore } = useGetCaregiverInfosQuery({
    variables: {
      first: FETCH_SIZE,
    },
  });

  const goToInfoUpdatePage = (infoId?: string) => {
    navigate(`/announcement/update-info${infoId ? `?infoId=${infoId}` : ""}`);
  };

  return {
    refs: {
      fetchMoreRef,
    },
    models: {
      data: data?.getCaregiverInfos.edges,
      pageInfo: data?.getCaregiverInfos.pageInfo,
      loading,
      fetchingMore,
    },
    operations: {
      goToInfoUpdatePage,
    },
  };
};
