import { useParams } from "react-router-dom";
import { BaseGraphProps } from "./MonthlyReportContainer";
import { useGetReceiverNameQuery } from "../../../lib/apollo/graphql/generated";

export const useMonthlyReportContainer = () => {
  const { receiverId, period } = useParams();

  if (!receiverId || !period) {
    throw new Error("수급자 ID 혹은 기간이 잘못 되었습니다.");
  }

  const [year, month] = period.split("-").map((value) => +value);

  const baseGraphProps: BaseGraphProps = {
    receiverId,
    year,
    month,
  };

  const { data, loading } = useGetReceiverNameQuery({
    variables: {
      receiverId,
    },
    skip: !receiverId,
  });

  return {
    models: {
      baseGraphProps,
      year,
      month,
      receiverId,
      receiver: data?.getReceiver.name,
      loading,
    },
  };
};
