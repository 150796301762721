import { useModal } from "../../../hooks/useModal";

export const useAccordion = (isLoading?: boolean) => {
  const [isAccordionOpen, { open, close }] = useModal();

  const onWrapperClick = () => {
    if (isLoading) {
      return;
    }

    if (!isAccordionOpen) {
      open();
    }
  };

  const onTitleBarClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (isLoading) {
      return;
    }

    if (isAccordionOpen) {
      close();
    } else {
      open();
    }
  };

  return {
    models: {
      isAccordionOpen,
    },
    operations: {
      onOpen: open,
      onClose: close,
      onTitleBarClick,
      onWrapperClick,
    },
  };
};
