import { useGetCaregiverManualQuery } from "../../../lib/apollo/graphql/generated";
import { BaseGraphProps } from "../containers/MonthlyReportContainer";

export const useCaregiverManual = ({
  receiverId,
  year,
  month,
}: BaseGraphProps) => {
  const { data, loading } = useGetCaregiverManualQuery({
    variables: {
      receiverId,
      year,
      month,
    },
  });

  return {
    models: {
      data: data?.getCaregiverManual,
      loading,
    },
  };
};
