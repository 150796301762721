import { useGetMonthlyReceiverStatusQuery } from "../../../lib/apollo/graphql/generated";
import { BaseGraphProps } from "../containers/MonthlyReportContainer";

export const useReceiverStatus = ({
  receiverId,
  year,
  month,
}: BaseGraphProps) => {
  const { data, loading } = useGetMonthlyReceiverStatusQuery({
    variables: {
      receiverId,
      year,
      month,
    },
  });

  const comment = data?.getMonthlyReceiverStatus.comment;
  const isCommented = !!comment;

  return {
    models: {
      comment,
      isCommented,
      loading,
    },
  };
};
