import styled from "styled-components";
import Accordion from "../../common/components/Accordion";
import { BaseGraphProps } from "../containers/MonthlyReportContainer";
import { useReceiverStatus } from "./ReceiverStatus.hook";

export default function ReceiverStatus(props: BaseGraphProps) {
  const {
    models: { comment, isCommented, loading },
  } = useReceiverStatus(props);

  return (
    <Accordion title="수급자 특이사항" isLoading={loading}>
      <Comment $isCommented={isCommented}>
        {isCommented ? <p>{comment}</p> : <p>특이사항이 없습니다</p>}
      </Comment>
    </Accordion>
  );
}

const Comment = styled.div<{ $isCommented: boolean }>(
  ({ $isCommented, theme }) => ({
    padding: "10px 0px 20px 0px",

    "&>p": {
      margin: 0,
      color: $isCommented ? theme.color.black : theme.color.darkGray,
    },
  })
);
