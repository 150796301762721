import { useEffect, useState } from "react";
import {
  CaregiverCommunityType,
  useGetCaregiverCommunitiesByTypeAsAdminLazyQuery,
  useGetCaregiverCommunitiesByTypeAsAdminQuery,
} from "../../../lib/apollo/graphql/generated";
import { getTwelveMonths } from "../../../utilities/format";

interface ChartDataset {
  labels: string[];
  datasets: [
    {
      label: string;
      data: Array<undefined | number>;
      borderWidth?: number;
      borderColor?: string;
    }
  ];
}

function useCommunityStats() {
  const [monthlyLoading, setMonthlyLoading] = useState(true);
  const [monthlyTrend, setMonthlyTrend] = useState<ChartDataset>();
  const { twelveMonths, thisMonth } = getTwelveMonths();

  const { data: generals, loading: generalLoading } =
    useGetCaregiverCommunitiesByTypeAsAdminQuery({
      variables: {
        type: CaregiverCommunityType.General,
        range: thisMonth,
      },
    });

  const { data: secrets, loading: secretLoading } =
    useGetCaregiverCommunitiesByTypeAsAdminQuery({
      variables: {
        type: CaregiverCommunityType.Secret,
        range: thisMonth,
      },
    });

  const [getCommunitiesByMonth] =
    useGetCaregiverCommunitiesByTypeAsAdminLazyQuery();

  useEffect(() => {
    (async () => {
      try {
        const allCounts = await Promise.all(
          twelveMonths.map(async (month) => {
            const { from, to } = month;

            try {
              const { data } = await getCommunitiesByMonth({
                variables: {
                  range: {
                    from,
                    to,
                  },
                },
              });

              return {
                [month.from.split("-")[0] + "." + month.from.split("-")[1]]:
                  data?.getCaregiverCommunitiesByTypeAsAdmin.edges?.length,
              };
            } catch (error) {
              console.log(`[Error at ${month}]`, error);
              return -1;
            }
          })
        );

        const labels = allCounts.map(
          (monthlyData) => Object.keys(monthlyData!)[0]
        );
        const counts = allCounts.map(
          (monthlyData) => Object.values(monthlyData!)[0]
        );

        setMonthlyTrend({
          labels,
          datasets: [
            {
              label: "새로운 게시물 수",
              data: counts,
              borderWidth: 1,
              borderColor: "red",
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setMonthlyLoading(false);
      }
    })();
  }, []);

  return {
    models: {
      monthlyTrend,
      generalCount:
        generals?.getCaregiverCommunitiesByTypeAsAdmin.edges?.length,
      secretCount: secrets?.getCaregiverCommunitiesByTypeAsAdmin.edges?.length,
      isLoading: !!(secretLoading || generalLoading || monthlyLoading),
    },
    operations: {},
  };
}

export default useCommunityStats;
