import styled from "styled-components";

interface BoxWithShadowProps {
  children: React.ReactNode;
}

export default function BoxWithShadow({ children }: BoxWithShadowProps) {
  return <Wrapper>{children}</Wrapper>;
}

const Wrapper = styled.div(({ theme }) => ({
  padding: theme.spacing.regular,
  backgroundColor: theme.color.white,
  height: "100%",
  width: "100%",
  borderRadius: 10,
  display: "flex",
  flexDirection: "column",
  gap: 10,
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.05)",
}));
