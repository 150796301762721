import { useState } from "react";
import { useGetMyInvitationCaregiversGuardiansQuery } from "../../../lib/apollo/graphql/generated";

export const useFriendsListContainer = () => {
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const { data, loading, fetchMore } =
    useGetMyInvitationCaregiversGuardiansQuery({
      variables: {
        first: 20,
      },
    });

  const onViewChange = (inView: boolean) => {
    if (isFetchingMore || !inView) {
      return;
    }

    setIsFetchingMore(true);

    fetchMore({
      variables: {
        first: 20,
        after: data?.getMyInvitationCaregiversGuardians?.pageInfo.endCursor,
      },
    });

    setIsFetchingMore(false);
  };

  return {
    models: {
      loading,
      isFetchingMore,
      pageInfo: data?.getMyInvitationCaregiversGuardians.pageInfo,
      data: data?.getMyInvitationCaregiversGuardians.edges,
    },
    operations: {
      onViewChange,
    },
  };
};
