import styled from "styled-components";

interface MonthlyReportGraphWrapperProps {
  title?: string;
  children?: React.ReactNode;
  padding?: React.CSSProperties["padding"];
}

interface WrapperProps {
  $padding: React.CSSProperties["padding"];
}

export default function GraphBox({
  children,
  title,
  padding = 20,
}: MonthlyReportGraphWrapperProps) {
  return (
    <Wrapper $padding={padding}>
      {title && <p>{title}</p>}
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div<WrapperProps>(({ theme, $padding }) => ({
  border: `1px solid ${theme.color.gray}`,
  width: "100%",
  borderRadius: 10,
  display: "flex",
  flexDirection: "column",
  height: "fit-content",
  padding: $padding,
  gap: 20,
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",

  "&>p:first-of-type": {
    fontWeight: "bold",
    fontSize: 22,
    margin: 0,
    color: theme.color.navy,
  },
}));
