import styled from "styled-components";

type InfoMedia = {
  __typename?: "Media";
  id: string;
  uri: string;
} | null;

interface MediaItemProps {
  src: string;
  onDelete: () => void;
}

function MediaItem({ src, onDelete }: MediaItemProps) {
  return (
    <MediaWrapper>
      <DeleteButton onClick={onDelete}>X</DeleteButton>
      <Media src={src} alt={`__preview_${src}`} />
    </MediaWrapper>
  );
}

interface InfoImagesProps {
  onMediaDelete: (id: string) => void;
  onMediaStateDelete: (index: number) => void;
  onMediaChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  mediaState: File[] | null;
  media?: InfoMedia[] | null;
}

export default function InfoImages({
  media,
  mediaState,
  onMediaDelete,
  onMediaStateDelete,
  onMediaChange,
}: InfoImagesProps) {
  return (
    <ImageWrapper>
      <ImageInputWrapper>
        <HiddenFileInput
          type="file"
          multiple
          accept="image/*"
          onChange={onMediaChange}
        />
        <ImageInputLabel htmlFor="hiddenFileInput">
          이미지 선택을 위해 클릭해 주세요.
        </ImageInputLabel>
      </ImageInputWrapper>
      <ImagePreviewsContainer>
        {!!media &&
          media.map((item) => {
            const uri = item?.uri || "";
            const id = item?.id || "";

            return <MediaItem src={uri} onDelete={() => onMediaDelete(id)} />;
          })}

        {!!mediaState &&
          mediaState.map((item, index) => {
            const uri = URL.createObjectURL(item);

            return (
              <MediaItem src={uri} onDelete={() => onMediaStateDelete(index)} />
            );
          })}

        {!mediaState?.length && !media?.length && (
          <NoImagesMessage>No Images Yet</NoImagesMessage>
        )}
      </ImagePreviewsContainer>
    </ImageWrapper>
  );
}

const ImageWrapper = styled.div(() => ({
  flex: 1,
  width: "100%",
}));

const ImageInputWrapper = styled.div(({ theme }) => ({
  position: "relative",
  cursor: "pointer",
  overflow: "hidden",
  borderRadius: "4px",
  border: `1px solid ${theme.color.gray}`,
  width: "100%",
}));

const HiddenFileInput = styled.input(() => ({
  opacity: 0,
  position: "absolute",
  width: "100%",
  height: "100%",
  cursor: "pointer",
  zIndex: 1,
  top: 0,
  left: 0,
  overflow: "hidden",
}));

const ImageInputLabel = styled.label(({ theme }) => ({
  border: `1px solid ${theme.color.gray}`,
  width: "100%",
  cursor: "pointer",
  transition: "0.1s ease-in",
  display: "block",
  padding: "8px",
  borderRadius: "4px",
  color: theme.color.darkGray,
  textAlign: "center",

  "&:hover": {
    border: `1px solid ${theme.color.darkNavy}`,
  },
}));

const ImagePreviewsContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  marginTop: "10px",
  maxHeight: "300px",
  overflowX: "auto",
  border: `2px dashed ${theme.color.gray}`,
  minHeight: 200,
}));

const NoImagesMessage = styled.p(({ theme }) => ({
  color: theme.color.gray,
  fontStyle: "italic",
  width: "100%",
  height: 200,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: 0,
}));

const DeleteButton = styled.button(({ theme }) => ({
  position: "absolute",
  background: "none",
  border: "none",
  color: theme.color.black,
  cursor: "pointer",
  fontSize: "14px",
  fontWeight: "bold",
  margin: "5px",
  transition: "0.1s ease-in",
  right: 3,
  top: 3,
  backgroundColor: theme.color.lightGray,
  padding: "3px 8px",
  borderRadius: 5,

  "&:hover": {
    color: theme.color.error,
  },
}));

const MediaWrapper = styled.div(() => ({
  position: "relative",
  margin: "5px",
}));

const Media = styled.img(() => ({
  maxWidth: "200px",
  maxHeight: "200px",
  margin: "5px",
}));
