import styled from "styled-components";
import Accordion from "../../common/components/Accordion";
import { BaseGraphProps } from "../containers/MonthlyReportContainer";
import { useReceiverWarnings } from "./ReceiverWarnings.hook";

export default function ReceiverWarnings(props: BaseGraphProps) {
  const {
    models: { loading, warningTree },
  } = useReceiverWarnings(props);

  return (
    <Accordion title="수급자 주의사항" isLoading={loading}>
      <div>
        {Object.keys(warningTree).map((category, index) => {
          const warnings = warningTree[category];

          return (
            <CategoryItem key={`__warning_category_${index}`}>
              <p>{category}</p>
              {warnings.map((warning, index) => (
                <WarningItem key={`__warning_${index}`}>
                  <Warning $variant="problem">
                    <span>문제사례</span>
                    <p>{warning.problem}</p>
                  </Warning>
                  <Warning $variant="solution">
                    <span>대처방안</span>
                    <p>{warning.solution}</p>
                  </Warning>
                </WarningItem>
              ))}
            </CategoryItem>
          );
        })}
      </div>
    </Accordion>
  );
}

const CategoryItem = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  padding: "10px 0px",
  gap: 5,

  "&>p:first-of-type": {
    fontWeight: "bold",
    textDecoration: "underline",
    fontSize: 16,
  },
}));

const WarningItem = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 5,
  marginBottom: 10,
}));

const Warning = styled.div<{ $variant: "problem" | "solution" }>(
  ({ $variant }) => ({
    display: "flex",
    alignItems: "center",
    gap: 10,

    "&>span": {
      fontWeight: "bold",
      padding: "5px 10px",
      borderRadius: 20,
      backgroundColor: $variant === "problem" ? "indianred" : "forestgreen",
      color: "white",
      fontSize: 14,
      flexShrink: 0,
    },

    "&>p": {
      margin: 0,
    },
  })
);
