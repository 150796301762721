import {
  GetCareServiceQuery,
  useGetCareServiceQuery,
} from "../../../lib/apollo/graphql/generated";
import { BaseGraphProps } from "../containers/MonthlyReportContainer";

export type WarningTree = {
  [key: string]: {
    problem: string;
    solution: string;
  }[];
};

const getWarningTree = (data?: GetCareServiceQuery) => {
  const tree: WarningTree = {};

  if (!data || !data?.getCareService.length) {
    return {};
  }

  data.getCareService.forEach((warning) => {
    if (
      !warning?.serviceCateNm ||
      !warning?.problemNm ||
      !warning?.problemSolutionNm
    ) {
      return;
    }

    const branch = {
      problem: warning.problemNm,
      solution: warning.problemSolutionNm,
    };

    if (tree[warning?.serviceCateNm]) {
      tree[warning.serviceCateNm].push(branch);
    } else {
      tree[warning.serviceCateNm] = [branch];
    }
  });

  return tree;
};

export const useReceiverWarnings = ({
  receiverId,
  year,
  month,
}: BaseGraphProps) => {
  const { data, loading } = useGetCareServiceQuery({
    variables: {
      receiverId,
      year,
      month,
    },
  });

  const warningTree = getWarningTree(data);

  return {
    models: {
      data: data?.getCareService,
      loading,
      warningTree,
      isEmpty: !Object.keys(warningTree).length,
    },
  };
};
