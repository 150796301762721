import useCaregiverList from "./CaregiverList.hook";
import TableHeader from "../../common/components/TableHeader";
import Table from "../../common/components/Table";
import ListPageTopSection from "../../common/components/ListPageTopSection";
import SearchBar from "../../common/components/SearchBar";
import PageWrapper from "../../common/components/PageWrapper";
import TableRow from "../../common/components/TableRow";
import TableColumn from "../../common/components/TableColumn";
import { formatPhoneNumber } from "../../../utilities/format";
import dayjs from "dayjs";
import ColoredLabel from "../../common/components/ColoredLabel";
import { Gender } from "../../../lib/apollo/graphql/generated";
import GenderSelector from "../components/GenderSelector";
import AddUserButton from "../components/AddUserButton";

export default function CaregiverList() {
  const {
    models: { data, pageInfo, loading, filters, isFetchingMore, isSearched },
    operations: {
      onRouteToUserDetailPage,
      onSearch,
      onFilterChange,
      onViewChange,
      onCancelSearch,
    },
  } = useCaregiverList();

  return (
    <PageWrapper>
      <ListPageTopSection>
        <GenderSelector
          gender={filters.gender}
          onChange={(gender) => onFilterChange("gender", gender)}
        />
        <SearchBar
          value={filters.query}
          placeholder="보호사 이름 혹은 전화번호"
          onChange={(query) => {
            onFilterChange("query", query);
          }}
          onSearch={onSearch}
          onCancel={isSearched ? onCancelSearch : undefined}
        />
        <AddUserButton type="caregivers" />
      </ListPageTopSection>
      <TableHeader
        tabs={[
          { label: "이름" },
          { label: "닉네임" },
          { label: "성별" },
          { label: "전화번호" },
          { label: "생년월일" },
          { label: "포인트" },
          { label: "가입날" },
          { label: "자격증 사진 여부" },
          { label: "자격번호" },
          { label: "삭제일" },
        ]}
      />
      <Table
        isLoading={loading}
        isEmpty={!data?.length}
        emptyMessage="조건에 맞는 보호사가 없습니다."
        infiniteScroll={{
          hasNextPage: pageInfo?.hasNextPage || false,
          isFetchingMore,
          onViewChange,
        }}
      >
        {data?.map((caregiver) => {
          const isDeleted = !!caregiver?.deletedAt;
          const hasCertificationImage = !!caregiver?.certificateMedia?.uri;

          return (
            <TableRow onClick={() => onRouteToUserDetailPage(caregiver?.id)}>
              <TableColumn>{caregiver?.name}</TableColumn>
              <TableColumn>{caregiver?.username}</TableColumn>
              <TableColumn>
                {caregiver?.gender === Gender.Male ? "남" : "여"}
              </TableColumn>
              <TableColumn>
                {!isDeleted && formatPhoneNumber(caregiver?.phone)}
              </TableColumn>
              <TableColumn>
                {dayjs(caregiver?.dateOfBirth).format("YYYY년 MM월 DD일")}
              </TableColumn>
              <TableColumn>{caregiver?.point} P</TableColumn>
              <TableColumn>
                {dayjs(caregiver?.createdAt).format("YY.MM.DD HH:MM")}
              </TableColumn>
              <TableColumn>
                <ColoredLabel
                  backgroundColor={hasCertificationImage ? "green" : "red"}
                  text={hasCertificationImage ? "있음" : "없음"}
                />
              </TableColumn>
              <TableColumn>{caregiver?.certificateNumber}</TableColumn>
              <TableColumn>
                {isDeleted &&
                  dayjs(caregiver?.deletedAt).format("YY.MM.DD HH:MM")}
              </TableColumn>
            </TableRow>
          );
        })}
      </Table>
    </PageWrapper>
  );
}
