import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import PageWrapper from "../../../modules/common/components/PageWrapper";
import styled from "styled-components";
import { User } from "firebase/auth";
import BasicButton from "../../../modules/common/components/BasicButton";

interface RestrictedOutletProps {
  isAdmin: boolean;
  user: User | null;
}

const SUB_ADMIN_PERMITTED = ["/friends"];

export default function RestrictedOutlet({
  isAdmin,
  user,
}: RestrictedOutletProps) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const hasFreshLogin = localStorage.getItem("fresh-login");

    if (user && hasFreshLogin) {
      const defaultPath = isAdmin ? "/" : "/friends";

      localStorage.removeItem("fresh-login");

      if (location.pathname !== defaultPath) {
        navigate(defaultPath, { replace: true });
      }
    }
  }, [user, isAdmin, navigate, location.pathname]);

  /**
   * If the user is admin, show them any page
   */
  if (isAdmin) {
    return <Outlet />;
  }

  /**
   * If user is a sub-admin and the page requested is permitted for sub-admins
   */
  if (SUB_ADMIN_PERMITTED.includes(location.pathname)) {
    return <Outlet />;
  }

  /**
   * User is a sub-admin on a unpermitted page
   * Show them an unauthorized warning
   */
  return (
    <PageWrapper>
      <Messages>
        <p>Unauthorized</p>
        <p>권한이 없는 페이지에 접근하셨습니다.</p>
        <p>현재 사용 중인 계정: {user?.email}</p>
      </Messages>
      <BasicButton
        text="돌아가기"
        onClick={() => {
          navigate(-1);
        }}
      />
    </PageWrapper>
  );
}

const Messages = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: 20,

  "&>p": {
    margin: 0,
    fontSize: 20,
    color: theme.color.darkGray,

    "&:first-of-type": {
      fontWeight: "bold",
      color: theme.color.navy,
      fontSize: 24,
      marginBottom: 10,
    },
  },
}));
