import styled from "styled-components";
import { BaseGraphProps } from "../containers/MonthlyReportContainer";
import {
  Keywords,
  Percentages,
  useKeywordAndCategoryGraph,
} from "./KeywordAndCategoryGraph.hook";
import React from "react";
import Loader from "../../common/components/Loader";
import GraphBox from "./GraphBox";
import Divider from "../../common/components/Divider";

interface CategoryGraphSegmentProps {
  $width: React.CSSProperties["width"];
  $backgroundColor: React.CSSProperties["backgroundColor"];
}

interface CategoryLabelProps {
  $backgroundColor: React.CSSProperties["backgroundColor"];
}

interface KeywordProps {
  $isTopThree: boolean;
}

export default function KeywordAndCategoryGraph(props: BaseGraphProps) {
  const {
    models: { loading, percentages, keywords },
  } = useKeywordAndCategoryGraph(props);

  if (loading) {
    return <KeywordAndCategoryGraphLoader />;
  }

  return (
    <>
      <CategoryGraph percentages={percentages} />
      <KeywordBubbleChart keywords={keywords} />
    </>
  );
}

/**
 * Loader component for when the graph data is loading
 */
function KeywordAndCategoryGraphLoader() {
  return (
    <>
      {["비중 높은 카테고리", "자주 언급된 키워드"].map((title) => (
        <GraphBox title={title}>
          <div style={{ height: 200 }}>
            <Loader />
          </div>
        </GraphBox>
      ))}
    </>
  );
}

/**
 * Category cate bar graph component
 */
function CategoryGraph({ percentages }: { percentages: Percentages }) {
  return (
    <GraphBox title="비중 높은 카테고리">
      <CateBarGraphWrapper>
        {percentages.map((item) => (
          <CategoryGraphSegment
            $width={item.percentage}
            $backgroundColor={item.color}
            key={`__category_${item.category}`}
          />
        ))}
      </CateBarGraphWrapper>
      <CategoryList>
        {percentages.map((item) => (
          <CategoryLabel $backgroundColor={item.color}>
            <div></div>
            <span>{item.category}</span>
            <span>{item.percentage}</span>
          </CategoryLabel>
        ))}
      </CategoryList>
    </GraphBox>
  );
}

/**
 * Keyword bubble chart component
 */
function KeywordBubbleChart({ keywords }: { keywords: Keywords }) {
  return (
    <GraphBox title="자주 언급된 키워드">
      <KeywordList>
        {keywords.map((item, index) => (
          <>
            <Keyword $isTopThree={index < 3} key={`__keyword_${item.keyword}`}>
              <p>
                {index + 1}. {item.keyword}
              </p>
              <p>{item.count}회</p>
            </Keyword>
            {index !== keywords.length - 1 && <Divider />}
          </>
        ))}
      </KeywordList>
    </GraphBox>
  );
}

const CateBarGraphWrapper = styled.div(({ theme }) => ({
  height: 30,
  borderRadius: 30,
  display: "flex",
  overflow: "hidden",
  backgroundColor: "#5e5e5e",
  border: `1px solid ${theme.color.gray}`,
}));

const CategoryGraphSegment = styled.div<CategoryGraphSegmentProps>(
  ({ $width, $backgroundColor }) => ({
    height: "100%",
    width: $width,
    backgroundColor: $backgroundColor,
  })
);

const CategoryList = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 5,
}));

const CategoryLabel = styled.div<CategoryLabelProps>(
  ({ $backgroundColor, theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: 10,

    "&>div": {
      height: 30,
      width: 30,
      backgroundColor: $backgroundColor,
      borderRadius: 5,
    },

    "&>span": {
      color: theme.color.darkGray,
      textAlign: "end",

      "&:last-of-type": {
        marginLeft: "auto",
      },
    },
  })
);

const KeywordList = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 3,
}));

const Keyword = styled.div<KeywordProps>(({ $isTopThree, theme }) => ({
  display: "flex",
  gap: 3,
  justifyContent: "space-between",

  "&>p": {
    margin: 0,

    ...($isTopThree && {
      color: "royalblue",
      fontWeight: "bold",
    }),
  },
}));
