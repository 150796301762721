import PageWrapper from "../../common/components/PageWrapper";
import useServiceContainer from "./ServiceContainer.hook";
import ServiceItem from "../components/ServiceItem";
import AddServiceModal from "../components/AddServiceModal";
import Loader from "../../common/components/Loader";
import ItemsGridWrapper from "../../common/components/ItemsGridWrapper";
import BoxWithShadow from "../../common/components/BoxWithShadow";
import PageTopSection from "../../common/components/PageTopSection";

function ServiceContainer() {
  const {
    models: { mode, actives, data, loading, selectedServiceId },
    operations: { onInfoSelect, onModeChange },
  } = useServiceContainer();

  return (
    <>
      <AddServiceModal
        isOpen={!!selectedServiceId}
        onClose={() => onInfoSelect(null)}
        selectedServiceId={selectedServiceId!}
      />
      <PageWrapper>
        <BoxWithShadow>
          {loading ? (
            <Loader />
          ) : (
            <>
              <PageTopSection
                title={`부가서비스 목록 (총 ${
                  mode === "activesOnly" ? actives?.length : data?.length
                }개)`}
                onModeChange={onModeChange}
                onClick={() => onInfoSelect("new")}
              />
              <ItemsGridWrapper>
                {mode === "activesOnly"
                  ? actives?.map((service, index) => (
                      <ServiceItem
                        key={index}
                        service={service!}
                        onClick={(id: string | null) => onInfoSelect(id)}
                      />
                    ))
                  : data?.map((service, index) => (
                      <ServiceItem
                        key={index}
                        service={service!}
                        onClick={(id: string | null) => onInfoSelect(id)}
                      />
                    ))}
              </ItemsGridWrapper>
            </>
          )}
        </BoxWithShadow>
      </PageWrapper>
    </>
  );
}

export default ServiceContainer;
