import { createContext, useContext, useEffect, useState } from "react";
import { onAuthStateChanged, User } from "@firebase/auth";
import { AdminRole, ReactChildrenProps } from "global";
import { auth, firebaseSignOut } from "../../../lib/firebase";
import Loader from "../components/Loader";
import styled from "styled-components";

type AdminUser = {
  user: User | null;
  role: AdminRole | null;
};

const AdminUserContext = createContext<AdminUser>({
  user: null,
  role: null,
});

function AdminUserProvider({ children }: ReactChildrenProps) {
  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState<AdminUser>({
    user: null,
    role: null,
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setInitializing(false);

      if (!user) {
        setUser({ user: null, role: null });
        return;
      }

      const claims = (await user.getIdTokenResult()).claims;
      const isAdmin = claims?.admin;
      const isSubAdmin = claims?.subAdmin;

      if (!isAdmin && !isSubAdmin) {
        alert("운영자 권한이 없는 사용자입니다.");
        await firebaseSignOut();
        setUser({ user: null, role: null });

        return;
      }

      setUser({
        user,
        role: isAdmin ? "admin" : "subAdmin",
      });
    });

    return () => unsubscribe();
  }, []);

  if (initializing) {
    return (
      <IntializingWrapper>
        <Loader />
      </IntializingWrapper>
    );
  }

  return (
    <AdminUserContext.Provider value={user}>
      {children}
    </AdminUserContext.Provider>
  );
}

export default AdminUserProvider;

export function useAdminUserContext() {
  return useContext(AdminUserContext);
}

const IntializingWrapper = styled.div(() => ({
  height: "100vh",
  width: "100vw",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));
