import styled from "styled-components";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { useAdminUserContext } from "../../../modules/common/providers/AdminUserProvider";
import RestrictedOutlet from "./RestrictedOutlet";

export default function RouteLayout() {
  const { user, role } = useAdminUserContext();
  const isAdmin = role === "admin";

  return (
    <Wrapper>
      <Sidebar isAdmin={isAdmin} />
      <RightSideWrapper>
        <Topbar isAdmin={isAdmin} />
        <RestrictedOutlet isAdmin={isAdmin} user={user} />
      </RightSideWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div(() => ({
  position: "relative",
  width: "100vw",
  height: "100vh",
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "row",
}));

const RightSideWrapper = styled.div(() => ({
  flex: 7,
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));
