import styled from "styled-components";

interface PageWrapperProps {
  children: React.ReactNode;
  isColumnFlex?: boolean;
}

interface WrapperProps {
  $isColumnFlex: boolean;
}

function PageWrapper({ children, isColumnFlex = true }: PageWrapperProps) {
  return <Wrapper $isColumnFlex={isColumnFlex}>{children}</Wrapper>;
}

const Wrapper = styled.div<WrapperProps>(({ theme, $isColumnFlex }) => ({
  padding: theme.spacing.middle,
  minWidth: 1200,
  height: "100vh",
  display: "flex",
  flexDirection: $isColumnFlex ? "column" : "row",
  overflow: "hidden",
}));

export default PageWrapper;
