import styled from "styled-components";
import { useMonthlyReceord } from "./MonthlyRecord.hook";
import MonthlyRecordTableContents from "./MonthlyRecordTableContents";

interface MonthlyRecordProps {
  receiverId: string;
}

export default function MonthlyRecord({ receiverId }: MonthlyRecordProps) {
  const {
    models: { period, isLoading, data, receiver, isNextDisabled },
    operations: { onNextMonth, onPreviousMonth },
  } = useMonthlyReceord(receiverId);

  return (
    <Container>
      <TitleWrapper>
        <TextWrapper>
          <PeriodToggler>
            <i onClick={onPreviousMonth} className="bi bi-arrow-left-square" />
            <p>
              {period.year}년 {period.month}월 돌봄기록 현황
            </p>
            <i
              onClick={onNextMonth}
              className="bi bi-arrow-right-square"
              style={{
                cursor: isNextDisabled ? "default" : "pointer",
                color: isNextDisabled ? "lightgray" : "",
              }}
            />
          </PeriodToggler>
          <p>{receiver} 수급자님</p>
        </TextWrapper>
      </TitleWrapper>
      <ContentsWrapper>
        <TableHeader>
          <p>날짜</p>
          <p>일간 기록</p>
        </TableHeader>

        <MonthlyRecordTableContents
          receiverId={receiverId}
          isLoading={isLoading}
          period={period}
          data={data}
        />

        <TableFooter>
          <a
            target="_blank"
            href={`/detail/monthly/${receiverId}/${period.year}-${period.month}`}
            rel="noreferrer"
          >
            월간 리포트 <i className="bi bi-box-arrow-up-right" />
          </a>
        </TableFooter>
      </ContentsWrapper>
    </Container>
  );
}

const Container = styled.div(() => ({
  height: "fit-content",
  maxHeight: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 10,

  "& p": {
    margin: 0,
  },
}));

const TextWrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 5,

  "&>p": {
    margin: 0,
  },
}));

const ContentsWrapper = styled.div(({ theme }) => ({
  flexGrow: 1,
  overflow: "hidden",
  border: `1px solid ${theme.color.gray}`,
}));

const TitleWrapper = styled.div(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

const PeriodToggler = styled.div(({ theme }) => ({
  display: "flex",
  gap: 10,

  "&>i": {
    fontSize: 20,
    cursor: "pointer",
    color: theme.color.darkGray,
  },

  "&>p": {
    fontWeight: "bold",
    fontSize: 20,

    "&>span": {
      color: theme.color.darkGray,
      textDecoration: "underline",
      cursor: "pointer",
      transition: "0.1s ease-in",

      "&:hover": {
        color: theme.color.navy,
      },
    },
  },
}));

const TableHeader = styled.div(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 8fr",
  minWidth: 1000,
  backgroundColor: theme.color.gray,
  margin: 0,
  height: 40,
  borderBottom: "2px solid black",
  padding: "5px 0px 5px 150px",
  fontWeight: "bold",

  "&>p": {
    margin: 0,
    textAlign: "center",
  },
}));

const TableFooter = styled.div(({ theme }) => ({
  backgroundColor: theme.color.gray,
  height: 40,
  borderTop: "2px solid black",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",

  "&>a": {
    color: theme.color.darkGray,
    fontWeight: "bold",
    padding: "0px 10px",
    transition: "all 0.1s ease-in",
    cursor: "pointer",

    "&:hover": {
      color: theme.color.navy,
    },
  },
}));
