import { useGetReceiverKeywordsQuery } from "../../../lib/apollo/graphql/generated";
import { BaseGraphProps } from "../containers/MonthlyReportContainer";

export type Percentages = {
  category: string;
  percentage: React.CSSProperties["width"];
  color: React.CSSProperties["color"];
}[];

export type Keywords = {
  keyword: string;
  count: number;
}[];

const CATEGORY_COLORS: Record<string, string> = {
  건강: "#95BBF5",
  일상: "#93CE8C",
  활동: "#CAB9F1",
  위로: "#feb7c3",
  기분: "#E6C5A8",
  기타: "#5e5e5e",
};

export const useKeywordAndCategoryGraph = ({
  receiverId,
  year,
  month,
}: BaseGraphProps) => {
  const { data, loading } = useGetReceiverKeywordsQuery({
    variables: {
      receiverId,
      year,
      month,
    },
  });

  /**
   * The sum of all count values in keywords data
   */
  const totalCount =
    data?.getReceiverKeywords?.reduce((acc, raw) => {
      if (raw && raw.count) {
        return acc + raw.count;
      }

      return acc;
    }, 0) || 0;

  /**
   * Object keyed by category and the sum of all its count values
   */
  const countByCategory: Record<string, number> = {};
  /**
   * Object keyed by keyword and the sum of all its count values
   */
  const countByKeyword: Record<string, number> = {};

  data?.getReceiverKeywords?.forEach((raw) => {
    const category = raw?.category || "";
    const count = raw?.count || 0;
    const keyword = raw?.keyword || "";

    if (category) {
      const accumulated = countByCategory[category] || 0;

      if (!accumulated) {
        countByCategory[category] = 0;
      }

      countByCategory[category] = accumulated + count;
    }

    if (keyword) {
      const accumulated = countByKeyword[keyword] || 0;

      if (!accumulated) {
        countByKeyword[keyword] = 0;
      }

      countByKeyword[keyword] = accumulated + count;
    }
  });

  /**
   * Percentages by category
   */
  const percentages = Object.keys(countByCategory).map((category) => {
    const acc = countByCategory[category] || 0;
    const percentage = ((acc / totalCount) * 100).toFixed(2);
    const isEtc = ["없음", "기타"].includes(category) || !category;
    const name = isEtc ? "기타" : category;

    return {
      category: name,
      percentage: `${percentage}%`,
      color: CATEGORY_COLORS[name],
    };
  });

  /**
   * Top 10 Keywords by descending count order
   */
  const keywords = Object.entries(countByKeyword)
    .sort(([, countA], [, countB]) => countB - countA)
    .slice(0, 10)
    .map(([keyword, count]) => ({ keyword, count }));

  return {
    models: {
      loading,
      data: data?.getReceiverKeywords,
      percentages,
      keywords,
    },
  };
};
