import { useFriendsListContainer } from "./FriendsListContainer.hook";
import PageWrapper from "../../common/components/PageWrapper";
import Table from "../../common/components/Table";
import TableColumn from "../../common/components/TableColumn";
import TableHeader from "../../common/components/TableHeader";
import TableRow from "../../common/components/TableRow";
import UserAvatar from "../../common/components/UserAvatar";

export default function FriendsListContainer() {
  const {
    models: { loading, isFetchingMore, pageInfo, data },
    operations: { onViewChange },
  } = useFriendsListContainer();

  return (
    <PageWrapper>
      <TableHeader
        tabs={[
          { label: "사진" },
          { label: "이름" },
          { label: "닉네임" },
          { label: "쇼핑몰 연동여부" },
          { label: "댓글 수" },
          { label: "돌봄기록 수" },
          { label: "게시물 수" },
        ]}
      />

      <Table
        isLoading={loading}
        isEmpty={!data?.length}
        emptyMessage="아직 초대한 친구가 없습니다!"
        infiniteScroll={{
          hasNextPage: pageInfo?.hasNextPage || false,
          isFetchingMore,
          onViewChange,
        }}
      >
        {data?.map((friendData) => {
          const friend = friendData?.invitedCaregiver;

          return (
            <TableRow>
              <TableColumn>
                <UserAvatar
                  src={friend?.avatar?.uri || "/images/default_user_image.png"}
                  alt="친구 사진"
                  size={50}
                />
              </TableColumn>
              <TableColumn>{friend?.name}</TableColumn>
              <TableColumn>{friend?.username}</TableColumn>
              <TableColumn>{!!friend?.cafe24MemberId ? "Y" : "N"}</TableColumn>
              <TableColumn>{friend?.replyCount}개</TableColumn>
              <TableColumn>{friend?.dailyReportCount}개</TableColumn>
              <TableColumn>{friend?.communityCount}개</TableColumn>
            </TableRow>
          );
        })}
      </Table>
    </PageWrapper>
  );
}
