import PageWrapper from "../../common/components/PageWrapper";
import usePackageContainer from "./PackageContainer.hook";
import Loader from "../../common/components/Loader";
import PackageItem from "../components/PackageItem";
import ItemsGridWrapper from "../../common/components/ItemsGridWrapper";
import PageTopSection from "../../common/components/PageTopSection";
import BoxWithShadow from "../../common/components/BoxWithShadow";
import UpdatePackageModal from "../components/UpdatePackageModal";

function PackageContainer() {
  const {
    models: { actives, mode, data, loading, selectedPackageId },
    operations: { onModeChange, onPackageSelect },
  } = usePackageContainer();

  return (
    <>
      {selectedPackageId && (
        <UpdatePackageModal
          isOpen={!!selectedPackageId}
          onClose={() => onPackageSelect("")}
          selectedPackageId={selectedPackageId}
        />
      )}
      <PageWrapper>
        <BoxWithShadow>
          <PageTopSection
            title={`포인트상품 목록 (총 ${
              mode === "activesOnly" ? actives?.length : data?.length
            }개)`}
            onModeChange={onModeChange}
            onClick={() => onPackageSelect("new")}
            buttonText="상품 추가"
          />
          {loading ? (
            <Loader />
          ) : (
            <ItemsGridWrapper>
              {mode === "all"
                ? data?.map((packageData, index) => (
                    <PackageItem
                      key={index}
                      packageData={packageData!}
                      onClick={onPackageSelect}
                    />
                  ))
                : actives.map((packageData, index) => (
                    <PackageItem
                      key={index}
                      packageData={packageData!}
                      onClick={onPackageSelect}
                    />
                  ))}
            </ItemsGridWrapper>
          )}
        </BoxWithShadow>
      </PageWrapper>
    </>
  );
}

export default PackageContainer;
