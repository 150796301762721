import styled from "styled-components";
import BoxWithShadow from "../../common/components/BoxWithShadow";
import ChatRanking from "./ChatRanking";

export default function ChatStats() {
  return (
    <BoxWithShadow>
      <Title>Top 50 채팅</Title>
      <ChatRanking />
    </BoxWithShadow>
  );
}

const Title = styled.p(({ theme }) => ({
  color: theme.color.darkGray,
  fontWeight: "bold",
  fontSize: 18,
  margin: 0,
}));
