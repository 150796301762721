import { UserType } from "global";
import { useModal } from "../../../hooks/useModal";
import BasicButton from "../../common/components/BasicButton";
import PromoteToPartnerModal from "./PromoteToPartnerModal";

interface PromoteToPartnerButtonProps {
  isPartner: boolean;
  userId: string;
  userType: UserType;
}

export default function PromoteToPartnerButton({
  userId,
  userType,
  isPartner,
}: PromoteToPartnerButtonProps) {
  const [isPromoteModalOpen, { open, close }] = useModal();

  return (
    <>
      {isPromoteModalOpen && (
        <PromoteToPartnerModal
          open={isPromoteModalOpen}
          onClose={close}
          userType={userType}
          userId={userId}
        />
      )}
      <BasicButton
        text={isPartner ? "이미 파트너입니다" : "파트너로 전환"}
        width="100%"
        disabled={isPartner}
        onClick={open}
      />
    </>
  );
}
