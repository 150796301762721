import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./Editor.css";
import useEditor from "./Editor.hook";

const Quill = ReactQuill.Quill;

const Size = Quill.import("attributors/style/size");
Size.whitelist = ["18px", "22px"];

const Parchment = Quill.import("parchment");

const LineHeightStyle = new Parchment.Attributor.Style(
  "line-height",
  "line-height"
);

// Quill.register(Font, true);
Quill.register(Size, true);
Quill.register(LineHeightStyle, true);

interface EditorProps extends ReactQuill.ReactQuillProps {
  height?: string | number;
  mediaFolder?: string;
}

function Editor({
  height = 400,
  mediaFolder = "announcement",
  ...rest
}: EditorProps) {
  const {
    refs: { editorRef },
  } = useEditor(mediaFolder);

  const modules = {
    toolbar: {
      container: [[{ size: ["18px", "22px"] }], ["bold", "italic"], ["link"]],
    },
  };

  return (
    <ReactQuill
      ref={editorRef}
      theme="snow"
      style={{ width: "100%", height }}
      preserveWhitespace
      modules={modules}
      formats={[
        "header",
        "size",
        "bold",
        "italic",
        "link",
        "image",
        "video",
        "line-height",
      ]}
      {...rest}
    />
  );
}

export default Editor;
