import { useState } from "react";

export const useSidebar = (isAdmin: boolean) => {
  const DEFAULT_LOCATION = isAdmin ? "/" : "/friends";
  const [location, setLocation] = useState(DEFAULT_LOCATION);

  const onLocationSelect = (newLocation: string) => {
    setLocation(newLocation);
  };

  return {
    models: {
      location,
      DEFAULT_LOCATION,
    },
    operations: {
      onLocationSelect,
    },
  };
};
