import styled from "styled-components";

interface MockupPhoneProps {
  children: React.ReactNode;
  width?: React.CSSProperties["width"];
}

export default function MockupPhone({
  children,
  width = "30%",
}: MockupPhoneProps) {
  return <Wrapper $width={width}>{children}</Wrapper>;
}

const Wrapper = styled.div<{ $width: React.CSSProperties["width"] }>(
  ({ theme, $width }) => ({
    width: $width,
    minWidth: $width,
    height: "100%",
    padding: 10,
    paddingBottom: 0,
    borderRadius: 20,
    border: `3px solid ${theme.color.darkGray}`,
    borderTop: `10px solid ${theme.color.darkGray}`,
    borderBottom: `10px solid ${theme.color.darkGray}`,
  })
);
