import styled from "styled-components";
import { useInfoContainer } from "./InfoContainer.hook";
import { CaregiverInfo } from "../../../lib/apollo/graphql/generated";
import BasicButton from "../../common/components/BasicButton";
import FetchMore from "../../common/components/FetchMore";
import Loader from "../../common/components/Loader";
import PageWrapper from "../../common/components/PageWrapper";
import CaregiverInfoItem from "../components/CaregiverInfoItem";

function InfoContainer() {
  const {
    refs: { fetchMoreRef },
    models: { data, loading, fetchingMore, pageInfo },
    operations: { goToInfoUpdatePage },
  } = useInfoContainer();

  return (
    <PageWrapper>
      <PageContents>
        <ButtonWrapper>
          <BasicButton
            text="정보글 추가"
            onClick={() => goToInfoUpdatePage()}
          />
        </ButtonWrapper>
        {(loading || fetchingMore) && <Loader />}
        <InfoItemWrapper>
          {data?.map((info) => (
            <CaregiverInfoItem
              key={info?.id}
              info={info as CaregiverInfo}
              onInfoSelect={() => goToInfoUpdatePage(info?.id)}
            />
          ))}
          {pageInfo?.hasNextPage && <FetchMore fetchMoreRef={fetchMoreRef} />}
        </InfoItemWrapper>
      </PageContents>
    </PageWrapper>
  );
}

const PageContents = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  backgroundColor: theme.color.white,
  borderRadius: 10,
  padding: theme.spacing.regular,
  gap: 10,
}));

const ButtonWrapper = styled.div(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "end",
}));

const InfoItemWrapper = styled.div(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(20%, 1fr))",
  gridGap: 20,
  width: "100%",
  padding: theme.spacing.small,
  backgroundColor: theme.color.white,
  overflow: "auto",
  height: "100%",
}));

export default InfoContainer;
