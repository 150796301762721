import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { ADMIN_ROUTES } from "../utils/router";
import { AdminRoute } from "routes";
import { useGetCafe24TokenExpiryTimesAsAdminQuery } from "../../apollo/graphql/generated";
import { firebaseSignOut } from "../../firebase";

export const useTopbar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const fullPath = location.pathname;
  const key = (fullPath.split("/")[1].toUpperCase() ||
    "MAIN") as keyof AdminRoute;

  const [title, setTitle] = useState("");

  const isSameAsFullPath = useCallback(
    (path?: string) => {
      if (!path) {
        return false;
      }

      const staticPath = path.split("/:")[0];
      return fullPath.startsWith(staticPath);
    },
    [fullPath]
  );

  const getPathName = useCallback(() => {
    const route = ADMIN_ROUTES[key];

    if (route.children?.length) {
      const selected = route.children.find(({ path }) =>
        isSameAsFullPath(path)
      );

      setTitle(selected?.name || route.name!);
    } else {
      setTitle(route.name!);
    }
  }, [key, isSameAsFullPath]);

  useEffect(() => {
    getPathName();
  }, [location?.pathname, getPathName]);

  const { data, loading } = useGetCafe24TokenExpiryTimesAsAdminQuery();

  const onRefreshCafe24Token = () => {
    const CLIENT_ID = process.env.REACT_APP_CAFE24_CLIENT_ID;
    const REDIRECT_URI = process.env.REACT_APP_CAFE24_OAUTH_REDIRECT_URI;

    if (!CLIENT_ID || !REDIRECT_URI) {
      window.alert("env 설정에 오류가 있습니다. env 파일을 확인해 주세요.");
      return;
    }

    const OAUTH_URL = `https://carelogue.cafe24api.com/api/v2/oauth/authorize?redirect_uri=${REDIRECT_URI}&client_id=${CLIENT_ID}&response_type=code&scope=mall.read_mileage,mall.write_mileage,mall.read_customer,mall.write_customer`;

    window.location.href = OAUTH_URL;
  };

  const expiryTime = data?.getCafe24TokenExpiryTimesAsAdmin.refresh;
  const isExpired = !expiryTime || !!(new Date() >= new Date(expiryTime));

  const onSignOut = async () => {
    navigate("/", {
      replace: true,
    });

    await firebaseSignOut();
  };

  return {
    models: {
      title,
      tokenDataLoading: loading,
      expiryTime,
      isExpired,
    },
    operations: {
      onSignOut,
      onRefreshCafe24Token,
    },
  };
};
