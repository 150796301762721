import styled from "styled-components";
import Modal from "../../common/components/Modal";
import LabeledInput from "../../common/components/LabeledInput";
import BasicButton from "../../common/components/BasicButton";
import { usePromoteToPartnerModal } from "./PromoteToPartnerModal.hook";
import { UserType } from "global";

export interface PromoteToPartnerMoadlProps {
  userId: string;
  userType: UserType;
  open: boolean;
  onClose: () => void;
}

export default function PromoteToPartnerModal(
  props: PromoteToPartnerMoadlProps
) {
  const {
    models: {
      formValues,
      submitLoading,
      isPwFilledOut,
      isPasswordMatch,
      isFormReady,
    },
    operations: { onChange, onSubmit },
  } = usePromoteToPartnerModal(props);

  const { open, onClose } = props;

  return (
    <Modal title="파트너로 전환" open={open} onClose={onClose} height="800px">
      <Form onSubmit={onSubmit}>
        <Warnings>
          <p>파트너로 전환된 사용자는 일부 관리자 기능을 사용할 수 있습니다.</p>
          <p>올바른 사용자가 맞는지 확인해 주세요.</p>
        </Warnings>
        <LabeledInput label="사용자 ID" disabled value={formValues.userId} />
        <LabeledInput
          label="이메일 주소"
          type="email"
          value={formValues.email}
          onChange={(event) => {
            onChange("email", event.target.value);
          }}
        />
        <PasswordInputs>
          <LabeledInput
            label="비밀번호"
            type="password"
            value={formValues.pw}
            onChange={(event) => {
              onChange("pw", event.target.value);
            }}
          />
          <LabeledInput
            type="password"
            value={formValues.confirmPw}
            onChange={(event) => {
              onChange("confirmPw", event.target.value);
            }}
          />
          {isPwFilledOut && !isPasswordMatch && (
            <Error>비밀번호가 일치하지 않습니다.</Error>
          )}
        </PasswordInputs>
        <ButtonWrapper>
          <BasicButton
            onClick={onSubmit}
            text="제출"
            type="submit"
            width="50%"
            disabled={!isFormReady}
            loading={submitLoading}
          />
        </ButtonWrapper>
      </Form>
    </Modal>
  );
}

const Form = styled.form(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 20,
}));

const Warnings = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 3,
  marginBottom: 10,

  "&>p": {
    margin: 0,
    color: theme.color.darkGray,
    fontSize: 16,
  },
}));

const PasswordInputs = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 5,
}));

const ButtonWrapper = styled.div(() => ({
  marginTop: "auto",
  display: "flex",
  justifyContent: "center",
}));

const Error = styled.p(({ theme }) => ({
  margin: 0,
  fontWeight: "bold",
  fontSize: 16,
  color: theme.color.error,
}));
