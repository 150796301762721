import styled from "styled-components";
import { useAccordion } from "./Accordion.hook";
import Loader from "./Loader";

interface AccordionProps {
  title?: string;
  children?: React.ReactNode;
  isLoading?: boolean;
}

function AccordionLoader() {
  return (
    <div>
      <Loader />
    </div>
  );
}

export default function Accordion({
  title,
  children,
  isLoading,
}: AccordionProps) {
  const {
    models: { isAccordionOpen },
    operations: { onTitleBarClick, onWrapperClick },
  } = useAccordion(isLoading);

  return (
    <Wrapper onClick={onWrapperClick} $isLoading={isLoading}>
      <p onClick={onTitleBarClick}>
        {title}
        {isLoading ? <AccordionLoader /> : <i className="bi bi-chevron-down" />}
      </p>
      {!isLoading && isAccordionOpen && children}
    </Wrapper>
  );
}

const Wrapper = styled.div<{ $isLoading?: boolean }>(
  ({ theme, $isLoading }) => ({
    padding: "0 20px",
    borderRadius: 10,
    border: `1px solid ${theme.color.gray}`,
    boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",

    "&>p": {
      margin: 0,
      fontSize: 18,
      padding: "20px 0",
      fontWeight: "bold",
      color: theme.color.darkGray,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      cursor: $isLoading ? "default" : "pointer",
    },
  })
);
