import styled from "styled-components";
import { VictoryAxis, VictoryLine, VictoryTheme } from "victory";
import { VictoryChart } from "victory-chart";
import { useStatusGraph } from "./StatusGraph.hook";
import Loader from "../../common/components/Loader";
import { BaseGraphProps } from "../containers/MonthlyReportContainer";
import GraphBox from "./GraphBox";

export default function StatusGraph({
  receiverId,
  year,
  month,
}: BaseGraphProps) {
  const {
    models: { categories, coordinates, loading, smallCategory },
    operations: { onCategorySelect },
  } = useStatusGraph({ receiverId, year, month });

  if (loading) {
    return (
      <GraphBox padding={0}>
        <TitleWrapper>
          <p>상태 변환 추이</p>
          <p>{smallCategory}</p>
        </TitleWrapper>
        <Loader />
      </GraphBox>
    );
  }

  return (
    <GraphBox padding={0}>
      <TitleWrapper>
        <p>상태 변화 추이</p>
        <CategoryDropdown onChange={onCategorySelect}>
          {categories?.map((category) => (
            <option
              key={category}
              value={category}
              selected={smallCategory === category}
            >
              {category}
            </option>
          ))}
        </CategoryDropdown>
      </TitleWrapper>
      <VictoryChart theme={VictoryTheme.clean} height={300} width={500}>
        <VictoryLine
          data={coordinates}
          animate={{
            duration: 300,
            onLoad: { duration: 1000 },
          }}
        />
        <VictoryAxis
          dependentAxis
          tickValues={[0, 1, 2, 3, 4, 5]}
          tickFormat={["없음", "관리", "주의", "불편", "보통", "양호"]}
          style={{
            axis: { stroke: "none" },
            tickLabels: { fontSize: 12 },
            grid: { strokeDasharray: "10", stroke: "#e0e0e0" },
          }}
        />
        <VictoryAxis
          tickValues={["1주", "2주", "3주", "4주", "5주"]}
          style={{
            axis: { stroke: "none" },
            tickLabels: { fontSize: 12, fill: "black" },
            grid: { stroke: "none" },
          }}
        />
      </VictoryChart>
    </GraphBox>
  );
}

const TitleWrapper = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "20px 10px 0 20px",
  gap: 5,

  "&>p:first-of-type": {
    margin: 0,
    fontSize: 22,
    fontWeight: "bold",
    color: theme.color.navy,
  },
}));

const CategoryDropdown = styled.select(({ theme }) => ({
  padding: 5,
  borderRadius: 5,
  width: "70%",
  maxWidth: 300,
  border: `1px solid ${theme.color.darkGray}`,
}));
