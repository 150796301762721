import { Route, Routes } from "react-router-dom";
import { useAdminUserContext } from "../../../modules/common/providers/AdminUserProvider";
import { ADMIN_ROUTES, PUBLIC_ROUTES } from "../utils/router";
import RouteLayout from "../components/RouteLayout";
import { CustomRoute } from "routes";

export default function RouteContainer() {
  const { user } = useAdminUserContext();

  return (
    <Routes>
      {user ? (
        <Route element={<RouteLayout />}>
          {Object.values(ADMIN_ROUTES).map((route: CustomRoute) => (
            <Route key={route.path} path={route.path} element={route.element}>
              {route.children?.map((childRoute) => (
                <Route
                  key={childRoute.path}
                  path={childRoute.path}
                  element={childRoute.element}
                />
              ))}
            </Route>
          ))}
        </Route>
      ) : (
        Object.values(PUBLIC_ROUTES).map((route: CustomRoute) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))
      )}
    </Routes>
  );
}
