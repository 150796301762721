import { Bar } from "react-chartjs-2";
import BoxWithShadow from "../../common/components/BoxWithShadow";
import useUserStats from "./UserStats.hook";
import styled from "styled-components";
import ColoredLabel from "../../common/components/ColoredLabel";
import Loader from "../../common/components/Loader";

function UserStats() {
  const {
    models: { userTrend, currentMonth, isLoading },
  } = useUserStats();

  return (
    <BoxWithShadow>
      {isLoading ? (
        <Loader />
      ) : (
        <Wrapper>
          <TextWrapper>
            <p>이번달 회원 현황</p>
            <ColoredLabel
              text={`요양보호사: ${currentMonth?.caregiverCount}명`}
              backgroundColor="red"
            />
            <ColoredLabel
              text={`보호자: ${currentMonth?.guardianCount}명`}
              backgroundColor="blue"
            />
            <ColoredLabel
              text={`수급자: ${currentMonth?.receiverCount}명`}
              backgroundColor="green"
            />
            <ColoredLabel
              text={`탈퇴: ${currentMonth?.resignedUsersCount}명`}
              backgroundColor="gray"
            />
          </TextWrapper>
          <ChartWrapper>
            <Bar data={userTrend!} />
          </ChartWrapper>
        </Wrapper>
      )}
    </BoxWithShadow>
  );
}

const Wrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  height: 360,
}));

const TextWrapper = styled.div(({ theme }) => ({
  display: "flex",
  gap: 10,
  alignItems: "center",

  "&>p": {
    margin: 0,
  },

  "&>p:first-of-type": {
    fontWeight: "bold",
    fontSize: 14,
    color: theme.color.darkGray,
  },
}));

const ChartWrapper = styled.div(() => ({
  height: "100%",
  width: "100%",
  display: "flex",
  justifyContent: "center",
}));

export default UserStats;
