import styled from "styled-components";
import { useInfoUpdateContainer } from "./InfoUpdateContainer.hook";
import Loader from "../../common/components/Loader";
import RegionSelector from "../../common/components/RegionSelector";
import BasicButton from "../../common/components/BasicButton";
import PageWrapper from "../../common/components/PageWrapper";
import InfoEditor from "../components/InfoEditor";
import InfoImages from "../components/InfoImages";

export default function InfoUpdateContainer() {
  const {
    models: {
      loading,
      state,
      data,
      updateLoading,
      validatedBody,
      isForAllRegions,
      isUpdate,
    },
    operations: {
      changeCaregiverInfoInput,
      changeMedia,
      onMediaStateRemove,
      onMediaRemove,
      onSelectAllRegions,
      onRemoveCity,
      onAddCity,
      onSubmit,
    },
  } = useInfoUpdateContainer();

  if (loading) {
    return <Loader />;
  }

  return (
    <PageWrapper>
      <Wrapper>
        <FormWrapper>
          <ContentsWrapper>
            <InfoEditor body={state.body} onChange={changeCaregiverInfoInput} />
            <InfoImages
              media={data?.media}
              mediaState={state.mediaState}
              onMediaChange={changeMedia}
              onMediaDelete={onMediaRemove}
              onMediaStateDelete={onMediaStateRemove}
            />
          </ContentsWrapper>
          <RegionsWrapper>
            <SelectedRegions>
              <RegionsTitle>배포될 지역들</RegionsTitle>
              <Regions>
                {state.cities && state.cities.length ? (
                  state.cities?.map((city, index) => {
                    return (
                      <RegionItem>
                        <span>
                          {city.stateName}{" "}
                          {city.name && city.stateCode ? city.name : "전체"}
                        </span>
                        <DeleteRegionButton
                          className="bi bi-trash3"
                          onClick={() => onRemoveCity(index)}
                        ></DeleteRegionButton>
                      </RegionItem>
                    );
                  })
                ) : (
                  <li>
                    {isForAllRegions
                      ? "전체글로 배포될 예정입니다."
                      : "아직 선택된 지역이 없습니다."}
                  </li>
                )}
              </Regions>
            </SelectedRegions>
            <RegionSelector
              onAdd={onAddCity}
              onSelectAllRegions={onSelectAllRegions}
              disabled={isForAllRegions}
            />
          </RegionsWrapper>
        </FormWrapper>
        <ButtonWrapper>
          <BasicButton
            text={
              !updateLoading ? (isUpdate ? "정보글 수정" : "정보글 등록") : ""
            }
            icon={updateLoading && <Loader />}
            disabled={!validatedBody || updateLoading}
            width="30%"
            onClick={onSubmit}
          />
        </ButtonWrapper>
      </Wrapper>
    </PageWrapper>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  gap: theme.spacing.large,
}));

const ButtonWrapper = styled.div(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  marginTop: "auto",
}));

const FormWrapper = styled.div(({ theme }) => ({
  display: "flex",
  gap: theme.spacing.large,
  width: "100%",
  height: "100%",
}));

const ContentsWrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "65%",
  justifyContent: "space-between",
  gap: 10,
}));

const RegionsWrapper = styled.div(() => ({
  width: "35%",
}));

const SelectedRegions = styled.div(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing.small,
}));

const RegionItem = styled.li(({ theme }) => ({
  display: "flex",
  gap: theme.spacing.small,
  alignItems: "center",
}));

const DeleteRegionButton = styled.i(({ theme }) => ({
  fontSize: 12,
  cursor: "pointer",
  transition: "0.3s ease-out",

  "&:hover": {
    color: theme.color.error,
  },
}));

const RegionsTitle = styled.label(() => ({
  fontWeight: "bold",
}));

const Regions = styled.ul(({ theme }) => ({
  width: "100%",
  color: theme.color.darkGray,
  listStyle: "none",
  paddingLeft: 0,
}));
