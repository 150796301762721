import React, { useState } from "react";
import { firebaseSignIn } from "../../../lib/firebase";

export const useAuthContainer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    const { value } = event.target;

    setState((previous) => ({
      ...previous,
      [key]: value,
    }));
  };

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    const { email, password } = state;

    try {
      localStorage.setItem("fresh-login", "true");

      await firebaseSignIn(email, password).finally(() => {
        setIsLoading(false);
      });
    } catch (error) {
      alert("아이디 혹은 비밀번호가 잘못되었습니다.");
    }
  };

  return {
    models: {
      email: state.email,
      password: state.password,
      isLoading,
    },
    operations: {
      onChange,
      onSubmit,
    },
  };
};
