import styled from "styled-components";
import Editor from "../../common/components/Editor";
import { useEffect, useRef, useState } from "react";

interface InfoEditorProps {
  body: string;
  onChange: (newValue: string) => void;
}

export default function InfoEditor({ body, onChange }: InfoEditorProps) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const getWrapperHeight = () => {
      if (wrapperRef.current) {
        const height = wrapperRef.current.offsetHeight;
        setHeight(height);
      }
    };

    getWrapperHeight();

    window.addEventListener("resize", getWrapperHeight);

    return () => window.removeEventListener("resize", getWrapperHeight);
  }, []);

  return (
    <Wrapper ref={wrapperRef}>
      <Editor value={body} onChange={onChange} height={height - 45} />
    </Wrapper>
  );
}

const Wrapper = styled.div(() => ({
  height: "100%",
  width: "100%",
  overflow: "hidden",
}));
