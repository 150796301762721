import { useState } from "react";
import {
  CreateAdminUserAsAdminInput,
  useCreateAdminUserAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";
import { PromoteToPartnerMoadlProps } from "./PromoteToPartnerModal";

export const usePromoteToPartnerModal = ({
  userId,
  userType,
  onClose,
}: PromoteToPartnerMoadlProps) => {
  const [formValues, setFormValues] = useState({
    userId,
    email: "",
    pw: "",
    confirmPw: "",
  });

  const [promoteUser, { loading }] = useCreateAdminUserAsAdminMutation({
    onError: () => {
      window.alert("에러가 발생했습니다. 나중에 다시 시도해 주세요.");
    },
    onCompleted: () => {
      window.alert("파트너로 전환되었습니다!");
      onClose();
    },
  });

  const onChange = <K extends Exclude<keyof typeof formValues, "userId">>(
    key: K,
    value: (typeof formValues)[K]
  ) => {
    const newValue = value.trim();

    setFormValues((previous) => ({
      ...previous,
      [key]: newValue,
    }));
  };

  const isFilledOut = userId && formValues.email;
  const isPwFilledOut = formValues.pw && formValues.confirmPw;
  const isPasswordMatch = formValues.confirmPw === formValues.pw;
  const isFormReady = isFilledOut && isPwFilledOut && isPasswordMatch;

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (!isFormReady) {
      return;
    }

    const createAdminUserAsAdminInput: CreateAdminUserAsAdminInput = {
      email: formValues.email,
      password: formValues.pw,
      ...(userType === "caregivers"
        ? {
            caregiverId: userId,
          }
        : {
            guardianId: userId,
          }),
    };

    promoteUser({
      variables: {
        createAdminUserAsAdminInput,
      },
    });
  };

  return {
    models: {
      formValues,
      isFilledOut,
      isPwFilledOut,
      isPasswordMatch,
      isFormReady,
      submitLoading: loading,
    },
    operations: {
      onChange,
      onSubmit,
    },
  };
};
