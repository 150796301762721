import styled, { ThemedComponentProps } from "styled-components";
import { useTopbar } from "./Topbar.hook";
import BasicButton from "../../../modules/common/components/BasicButton";
import { formatDate } from "../../../utilities/format";

interface TopbarProps {
  isAdmin: boolean;
}

export default function Topbar({ isAdmin }: TopbarProps) {
  const {
    models: { title, tokenDataLoading, expiryTime, isExpired },
    operations: { onRefreshCafe24Token, onSignOut },
  } = useTopbar();

  return (
    <Wrapper>
      <Pathname>{title}</Pathname>
      <AccountWrapper>
        {isAdmin && (
          <BasicButton
            text={
              !isExpired && !!expiryTime
                ? `카페24 토큰 갱신 (${formatDate(expiryTime, "MM.DD HH:MM")})`
                : "카페24 토큰 만료됨"
            }
            fontWeight="bold"
            onClick={onRefreshCafe24Token}
            width="300px"
            loading={tokenDataLoading}
          />
        )}
        <BasicButton
          text="Sign Out"
          fontWeight="bold"
          backgroundColor="red"
          hoverBackgroundColor="lightRed"
          onClick={async () => await onSignOut()}
          width="80px"
        />
      </AccountWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.small}px ${theme.spacing.regular}px`,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height: theme.topBarHeight,
}));

const Pathname = styled.div(({ theme }: ThemedComponentProps) => ({
  ...theme.font.head2,
  fontWeight: "bold",
}));

const AccountWrapper = styled.div(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing.small,
}));
