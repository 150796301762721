import { useState } from "react";
import { useGetMonthlyReceiverMeasureQuery } from "../../../lib/apollo/graphql/generated";
import { BaseGraphProps } from "../containers/MonthlyReportContainer";

export const useStatusGraph = ({ year, month, receiverId }: BaseGraphProps) => {
  const [smallCategory, setSmallCategory] = useState("");

  const onCategorySelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    setSmallCategory(value);
  };

  const { data, loading } = useGetMonthlyReceiverMeasureQuery({
    variables: {
      receiverId,
      year,
      month,
    },
    onCompleted: (data) => {
      const measures = data?.getMonthlyReceiverMeasure;

      if (!measures || !measures.length) {
        return;
      }

      setSmallCategory(measures[0]?.small_category!);
    },
  });

  /**
   * The object that has the same small_category value as smallCategory state
   */
  const selected = data?.getMonthlyReceiverMeasure.find(
    (item) => item?.small_category === smallCategory
  );

  /**
   * All small categories included in the server response
   */
  const categories = data?.getMonthlyReceiverMeasure.map(
    (item) => item?.small_category || ""
  );

  /**
   * Array of coordinates that will be passed to VictoryLine as data prop
   */
  const coordinates = selected?.weekly_measure?.map((item, index) => ({
    y: item,
    x: `${index + 1}주`,
  }));

  return {
    models: {
      coordinates,
      categories,
      loading,
      smallCategory,
    },
    operations: {
      onCategorySelect,
    },
  };
};
