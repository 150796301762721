import styled from "styled-components";
import ChatItem from "./ChatItem";
import useChatRanking from "./ChatRanking.hook";
import Loader from "../../common/components/Loader";

export default function ChatRanking() {
  const {
    models: { data, loading },
  } = useChatRanking();

  return (
    <Wrapper>
      {loading ? (
        <Loader />
      ) : (
        data?.map((chat, index) => <ChatItem key={index} chat={chat} />)
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div(() => ({
  height: "100%",
  overflow: "auto",
  display: "flex",
  flexDirection: "column",
  gap: 12,
  paddingRight: 10,
}));
