import styled from "styled-components";
import { Chart, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import Loader from "../../common/components/Loader";
import BoxWithShadow from "../../common/components/BoxWithShadow";
import useCommunityStats from "./CommunityStats.hook";
import ColoredLabel from "../../common/components/ColoredLabel";

function CommunityStats() {
  const {
    models: { isLoading, monthlyTrend, generalCount, secretCount },
  } = useCommunityStats();

  Chart.register(...registerables);

  return (
    <BoxWithShadow>
      {isLoading ? (
        <Loader />
      ) : (
        <Wrapper>
          <TextWrapper>
            <p>이번달 신규 게시물 수</p>
            <ColoredLabel
              text={`일상주제: ${generalCount}개`}
              backgroundColor="red"
            />
            <ColoredLabel
              text={`대나무숲: ${secretCount}개`}
              backgroundColor="blue"
            />
          </TextWrapper>
          <ChartWrapper>
            <Line
              key={JSON.stringify(monthlyTrend)}
              data={monthlyTrend!}
              options={{
                elements: {
                  line: {
                    tension: 0.3,
                  },
                  point: {
                    backgroundColor: "red",
                  },
                },
              }}
            />
          </ChartWrapper>
        </Wrapper>
      )}
    </BoxWithShadow>
  );
}

const Wrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  height: 360,
}));

const TextWrapper = styled.div(({ theme }) => ({
  display: "flex",
  gap: 10,
  alignItems: "center",

  "&>p": {
    margin: 0,
  },

  "&>p:first-of-type": {
    fontWeight: "bold",
    fontSize: 14,
    color: theme.color.darkGray,
  },
}));

const ChartWrapper = styled.div(() => ({
  height: "100%",
  width: "100%",
  display: "flex",
  justifyContent: "center",
}));

export default CommunityStats;
