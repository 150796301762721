import PageWrapper from "../../common/components/PageWrapper";
import styled from "styled-components";
import StatusGraph from "../components/StatusGraph";
import KeywordAndCategoryGraph from "../components/KeywordAndCategoryGraph";
import MockupPhone from "../../common/components/MockupPhone";
import { useMonthlyReportContainer } from "./MonthlyReportContainer.hook";
import Loader from "../../common/components/Loader";
import CaregiverManual from "../components/CaregiverManual";
import ReceiverStatus from "../components/ReceiverStatus";
import ReceiverWarnings from "../components/ReceiverWarnings";

export interface BaseGraphProps {
  receiverId: string;
  year: number;
  month: number;
}

export default function MonthlyReportContainer() {
  const {
    models: { baseGraphProps, receiver, year, month, loading },
  } = useMonthlyReportContainer();

  if (loading) {
    return <Loader />;
  }

  return (
    <PageWrapper isColumnFlex={false}>
      <MockupPhone>
        <Wrapper>
          <StatusGraph {...baseGraphProps} />
          <KeywordAndCategoryGraph {...baseGraphProps} />
        </Wrapper>
      </MockupPhone>
      <InfoWrapper>
        <Title>
          <p>
            {year}년 {month}월 월간기록
          </p>
          <p>{receiver} 수급자님</p>
        </Title>
        <InfoContents>
          <ReceiverWarnings {...baseGraphProps} />
          <ReceiverStatus {...baseGraphProps} />
          <CaregiverManual {...baseGraphProps} />
        </InfoContents>
      </InfoWrapper>
    </PageWrapper>
  );
}

const Wrapper = styled.div(() => ({
  display: "flex",
  alignSelf: "center",
  flexDirection: "column",
  width: "100%",
  padding: 10,
  height: "100%",
  gap: 20,
  alignItems: "center",
  overflowY: "auto",
}));

const InfoWrapper = styled.div(({ theme }) => ({
  flexGrow: 1,
  height: "100%",
  marginLeft: 20,
  overflowY: "auto",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  borderRadius: 10,
  border: `1px solid ${theme.color.gray}`,
  padding: 20,
}));

const Title = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 3,

  "&>p": {
    margin: 0,

    "&:first-of-type": {
      fontSize: 20,
      fontWeight: "bold",
    },
  },
}));

const InfoContents = styled.div(() => ({
  padding: "20px 0",
  display: "flex",
  flexDirection: "column",
  gap: 10,
}));
