import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import imageCompression from "browser-image-compression";
import {
  COMPRESSION_OPTION,
  FETCH_SIZE,
  MEDIA_LIMIT,
} from "../../../utilities/constants";
import {
  CaregiverInfoInput,
  GetCaregiverInfosDocument,
  useDeleteCaregiverInfoMediaMutation,
  useGetCaregiverInfoQuery,
  useUpdateCaregiverInfoAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";
import { City } from "../../common/components/RegionSelector.hook";

interface FormState {
  id: string | null;
  body: string;
  mediaState: File[] | null;
  cities: City[] | null;
}

export const useInfoUpdateContainer = () => {
  const [searchParams] = useSearchParams();
  const infoId = searchParams.get("infoId") || "";

  const [state, setState] = useState<FormState>({
    id: infoId || null,
    body: "",
    mediaState: null,
    cities: null,
  });

  const [isForAllRegions, setIsForAllRegions] = useState(false);

  const { data, loading } = useGetCaregiverInfoQuery({
    variables: {
      infoId,
    },
    onCompleted: (res) => {
      const existing = res?.getCaregiverInfo;

      if (!existing) {
        return;
      }

      const { id, body, cities } = existing;

      const _cities = cities?.length
        ? cities.map((city) => ({
            code: city?.cityCode || "",
            name: city?.name || "",
            stateCode: city?.state.stateCode || "",
            stateName: city?.state.name || "",
          }))
        : null;

      setState((previous) => ({
        ...previous,
        id,
        body,
        cities: _cities,
      }));
    },
    skip: !infoId,
  });

  const [updateCaregiverInfoAsAdmin, { loading: updateLoading }] =
    useUpdateCaregiverInfoAsAdminMutation({
      refetchQueries: [
        {
          query: GetCaregiverInfosDocument,
          variables: {
            first: FETCH_SIZE,
          },
        },
      ],
    });

  const [deleteCaregiverInfoMedia] = useDeleteCaregiverInfoMediaMutation();

  const validateHTMLString = (content: string) => {
    return !!content.replace(/<[^>]*>/g, "").trim();
  };

  const changeCaregiverInfoInput = (inputValue: string) => {
    setState((prev) => ({ ...prev, body: inputValue }));
  };

  const changeMedia = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event?.target;

    if (files) {
      const limit =
        MEDIA_LIMIT -
        (!!data?.getCaregiverInfo?.media
          ? data?.getCaregiverInfo?.media?.length
          : 0);

      const editedMediaState = state?.mediaState
        ? [...state?.mediaState, ...files]?.slice(0, limit)
        : [...files];

      setState((prev) => ({
        ...prev,
        mediaState: editedMediaState,
      }));
    }
  };

  const onMediaStateRemove = (index: number) => {
    if (!state?.mediaState) return;

    let editedMediaState = [...state?.mediaState];

    editedMediaState = [
      ...editedMediaState?.slice(0, index),
      ...editedMediaState?.slice(index + 1),
    ];

    setState((prev) => ({
      ...prev,
      mediaState: editedMediaState,
    }));
  };

  const onMediaRemove = (mediaId: string) => {
    deleteCaregiverInfoMedia({
      variables: {
        mediaId,
        infoId,
      },
    });
  };

  /**
   * - Handler for when user decides to publish the info-post for users of all regions
   * - `state.cities` is set to null
   */
  const onSelectAllRegions = () => {
    if (!isForAllRegions) {
      if (
        !window.confirm(
          "선택된 지역들은 사라집니다. 전체글로 배포하시겠습니까?"
        )
      ) {
        return;
      }

      setState((previous) => ({
        ...previous,
        cities: null,
      }));

      setIsForAllRegions(true);
    } else {
      setIsForAllRegions(false);
    }
  };

  /**
   * - Handler for when the user deicdes to remove a selected region
   */
  const onRemoveCity = (index: number) => {
    if (!state.cities) return;

    const copied = [...state?.cities];
    const filtered = [...copied.slice(0, index), ...copied.slice(index + 1)];

    setState((previous) => ({
      ...previous,
      cities: filtered,
    }));
  };

  /**
   * - Handler for when user selects a new region for the info-post to be published at
   */
  const onAddCity = (newCities: City[]) => {
    if (!state.cities) {
      setState((previous) => ({
        ...previous,
        cities: newCities,
      }));

      return;
    }

    let cities = [...state.cities];

    for (const newCity of newCities) {
      const { code, stateCode } = newCity;

      if (code) {
        // When there is a specified city
        cities = cities.filter(
          (city) => city.stateCode !== stateCode || city.code !== code
        );
      } else {
        // When it's for all cities of a specified state
        cities = cities.filter((city) => city.stateCode !== stateCode);
      }

      cities = [...cities, newCity];
    }

    setState((previous) => ({
      ...previous,
      cities: cities,
    }));
  };

  /**
   * - Handler for when user submits the form
   * - Alerts user and early returns if `!state.body`. Otherwise, updates or creates a caregiverInfo post
   */
  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const { body, cities, mediaState } = state;

    if (!validateHTMLString(body)) {
      window.alert("내용을 입력하세요.");
      return;
    }

    const cityIds: string[] = !!cities
      ? [...cities?.map((city) => city.code as string)]
      : [];

    const caregiverInfoInput: CaregiverInfoInput = {
      id: infoId || null,
      body,
      cityIds,
    };

    if (mediaState) {
      const mediaInputs = [];

      for await (let item of mediaState) {
        const compressedFile = await imageCompression(item, COMPRESSION_OPTION);
        mediaInputs.push({ file: compressedFile });
      }

      caregiverInfoInput.mediaInputs = mediaInputs;
    }

    await updateCaregiverInfoAsAdmin({
      variables: {
        caregiverInfoInput,
      },
    });
  };

  return {
    models: {
      loading,
      updateLoading,
      state,
      data: data?.getCaregiverInfo,
      validatedBody: validateHTMLString(state.body),
      isForAllRegions,
      isUpdate: !!infoId,
    },
    operations: {
      changeCaregiverInfoInput,
      changeMedia,
      onMediaStateRemove,
      onMediaRemove,
      onSubmit,
      onSelectAllRegions,
      onRemoveCity,
      onAddCity,
    },
  };
};
